
<template>
    <div class="layout">
        <Layout>
            <Header>
                <div id="header" :class="roll >= 1 ? 'hand-a' : 'hand-b'">
                    <Row class="ivu-text-center" :gutter="24" style="background: none; padding: 0;">
                        <Col flex="3">
                        </Col>    
                        <Col flex="5">
                            <!-- <Image :src="headLogin" width="24rem" height="5.25rem" :style="roll >= 1 ? 'filter: none;' : 'filter: grayscale(100%) brightness(500%);'" /> -->
                            <!-- <img :src="headLogin" :style="roll >= 1 ? 'width: 24rem; height: 5.25rem; filter: none;' : 'width: 24rem; height: 5.25rem; filter: grayscale(100%) brightness(500%);'" /> -->
                            <div class="ivu-image" data-v-604718e6="" :style="roll >= 1 ? 'width: 24rem; height: 5.25rem; filter: none;' : 'width: 24rem; height: 5.25rem; filter: grayscale(100%) brightness(500%);'"><!---->
                                <div class="ivu-image-inner">
                                    <img class="ivu-image-img" alt="" :src="headLogin" loading="eager" referrerpolicy=""><!---->
                                </div><!---->
                            </div>
                        </Col>
                        <Col flex="16" style="margin-left: 7.425rem;">
                            <Row>
                                <Col flex="12">
                                </Col>    
                                <Col flex="12" style="height:4.6rem;">
                                    <div class="tel" :style="roll >= 1 ? 'color: #641f77;' : 'color: #fff;'">
                                       <div class="tel-tip">联系电话</div>
                                       <div class="tel-phone">020-8944 8086</div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col flex="2" v-for="(item, i) in menu" :key="i" :style="roll >= 1 ? 'color: #000;' : 'color: #fff;'">
                                    <a :href="item.url" :style="roll >= 1 ? 'color: #000;' : 'color: #fff;'"><span class="homeClumn">{{ item.name }}</span></a>
                                </Col> 
                                <Col flex="5"></Col>  
                                <!-- <Menu mode="horizontal" theme="dark" active-name="1" >
                                    <MenuItem :name="item.index" v-for="(item, i) in menu" :key="i" :style="roll === 1 ? 'color: #000;' : 'color: #fff;'">
                                        <Icon :type="item.icon"></Icon>
                                        {{ item.name }}
                                    </MenuItem>
                                </Menu> -->
                            </Row>
                        </Col>
                    </Row>
                </div>
            </Header>

            <Content class="header-bg">
                <div class="ivu-image" data-v-604718e6=""><!---->
                    <div class="ivu-image-inner">
                        <img class="ivu-image-img" alt="" :src="headBg" loading="eager" referrerpolicy=""><!---->
                    </div><!---->
                </div>
            </Content>

            <Content class="content-video">
                <Row style="margin-top:7.5rem;">
                    <Col flex="2"></Col>
                    <Col flex="6">
                        <div class="ivu-card ivu-card-bordered video-card" data-v-47323bf2="">
                            <!---->
                            <!---->
                            <div class="ivu-card-body" style="padding: 0;">
                                <div data-v-47323bf2="" style="width: 100%; height: 100%;">
                                    <video src="https://msboss-prd.oss-cn-shenzhen.aliyuncs.com/webSite/01.mp4" x5-playsinline
                                    controls="" id="videoElement" data-v-47323bf2="" class="video-play" :style="videoFull === 1 ? 'object-fit: cover;' : 'object-fit: none;'" >
                                    </video>
                                    <!-- <svg-icon icon-class="fullscreen" @click="videoFullscreen(`videoElement`)" /> -->
                                </div>
                            </div>
                        </div>
                        </Col>
                    <Col flex="0.6"></Col>
                    <Col flex="12">
                        <div class="ivu-card ivu-card-bordered content-card">
                            <a href="https://www.zhongbenziteng.com/zf-pc/index.html#/">
                            <div class="ivu-card-body" style="">
                                <div class="content-tab" data-v-47323bf2="">
                                    <img src="../assets/tab-zf.png"/>
                                </div>
                                <span data-v-47323bf2="">中考复读</span>
                            </div>
                            </a>
                        </div>
                        <div class="ivu-card ivu-card-bordered content-card">
                            <a href="https://www.zhongbenziteng.com/gf-pc/index.html#/">
                                <div class="ivu-card-body" style="">
                                    <div class="content-tab" data-v-47323bf2="">
                                        <img src="../assets/tab-gf.png"/>
                                    </div>
                                    <span data-v-47323bf2="">高考复读</span>
                                </div>
                            </a>
                        </div>
                        <div class="ivu-card ivu-card-bordered content-card">
                            <a href="https://www.zhongbenziteng.com/yt-pc/index.html#/">
                                <div class="ivu-card-body" style="">
                                    <div class="content-tab" data-v-47323bf2="">
                                        <img src="../assets/tab-yts.png"/>
                                    </div>
                                    <span>艺体生<label style="font-size: 1.5rem;margin-left: 10px;">文化课培训</label></span>
                                </div>
                            </a>
                        </div>
                        <div class="ivu-card ivu-card-bordered content-card">
                            <a href="/overseas"><div class="ivu-card-body" style="">
                                <div class="content-tab" data-v-47323bf2="">
                                    <img src="../assets/tab-gj.png"/>
                                </div>
                                <span data-v-47323bf2="">海外留学</span>
                            </div>
                            </a>
                        </div>
                        <div class="ivu-card ivu-card-bordered content-card">
                            <a href="https://www.zhongbenziteng.com/zt-pc/pcgaozhi.html#/">
                            <div class="ivu-card-body" style="">
                                <div class="content-tab" data-v-47323bf2="">
                                    <img src="../assets/tab.png" />
                                </div>
                                <span data-v-47323bf2="">高职高考</span>
                            </div></a>
                        </div>
                        <div class="ivu-card ivu-card-bordered content-card">
                            <div class="ivu-card-body" style="">
                                <div class="content-tab" data-v-47323bf2="">
                                    <img src="../assets/tab.png"/>
                                </div>
                                <span data-v-47323bf2="">敬请期待</span>
                            </div>
                        </div>
                       
                    </Col>
                    <Col flex="2"></Col>
                </Row>
            </Content>

            <Content class="content-notice" v-if="roll >= 1">
                <Row style="margin-bottom: 1.875rem; margin-top: 5rem;">
                    <Col flex="3.2"></Col>
                    <Col flex="20.8"><img src="../assets/news-notice.png"/></Col>
                </Row>   
                <Row style="height: 35px; margin-top: 10px;">
                    <Col flex="3.2">
                    </Col>
                    <Col flex="11" >
                        <template v-for="(item, i) in actColumn" :key="i">
                            <a href="javascript:void(0);">
                                <div class="notice-cul" @mousemove="actColumnTo(item.type)" :class="actColumnType === item.type ? 'cul-checked ivu-tag-checked' : ''" data-v-47323bf2=""><!---->
                                <span>{{item.name}}</span><!---->
                            </div></a>
                        </template>
                    </Col>
                    <!-- <Col flex="7"></Col> -->
                    <Col flex="4">
                        <div class="notice-more" data-v-47323bf2=""><!---->
                            <a href="javascript:void(0);"><span class="ivu-tag-text" @click="toLinkTa(2)">查看更多</span></a><!---->
                        </div>
                    </Col>
                </Row> 
                <Row class="notice-lst">
                    <Col flex="2.5"></Col>
                    <Col flex="5">
                        <div class="notice-banner">
                            <a href="javascript:void(0);"  @click="toLinkTa(2)"><img src="https://msboss-prd.oss-cn-shenzhen.aliyuncs.com/webSite/PC/notice.png"/></a>
                        </div>
                    </Col>
                    <Col flex="10">
                        <List>
                            <ListItem v-for="(item, i) in activityNoticeAll" :key="i">
                                <div class="notice-date">
                                    <span style="font-size: 2.875rem;">{{item.day}}</span><br/>
                                    <span style="font-size: 1rem;">{{item.year+ '·' +item.month}}</span>
                                </div>
                                <div class="ivu-list-item-meta notice-item" data-v-47323bf2=""><!---->
                                    <div class="ivu-list-item-meta-content">
                                        <a  @click="toNofollow(item)"><div class="ivu-list-item-meta-title">{{item.title}}</div></a>
                                        <div class="ivu-list-item-meta-description">{{item.description}}</div>
                                    </div>
                                </div>
                            </ListItem>
                            
                        </List>    
                    </Col>
                    <Col flex="2.5"></Col>
                </Row>   
            </Content>

            <Content class="content-aboutUs" v-if="roll >= 1">
                <Row>
                    <Col flex="3.2"></Col>
                    <Col flex="20.8"><img src="../assets/brand-tip.png"/></Col>
                </Row>  
                <br/>
                <Row>
                    <Col flex="3"></Col>
                    <Col flex="19" class="about-founder">
                        <div class="founder-bg"></div>
                        <div class="founder-content">
                            重本紫腾15年丰富办学经验，率先采用 “按学科分层教学” 的科学教学模式，有效实现因材施教。亦师亦友的导师制，让老师陪伴着学生实现蜕变，华丽转身。独立的市中心综合大楼，封闭管理，有效提高学习效率，培养良好的学习习惯。学校办学成绩耀眼，助力学生圆梦理想学校。
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;重本·紫腾--选取 “重本” 作为 “姓”，寄语了学校致力提升学生自身学习能力，希望寻求教育本源和回归教育本质的愿望。选取 “紫腾” 作为 “名”，跟 “子腾” 同音，表达了学校坚持因材施教，授人以渔的办学理念，助力学子腾飞考入更高级别的学校。
                            <br/>
                            <!-- &nbsp;&nbsp;&nbsp;&nbsp;成才先成人，教育的根本就是育人。重本·紫腾中高考复读学校实施全员德育的管理体系，教师既是知识的传输者，也是学生道德的引路人。“全员德育”的开展旨在帮助学生树立正确的价值观，培养规则意识，激发学习兴趣，挖掘学习内驱力，全力冲刺中高考，为拼搏的人生描绘浓墨重彩的一笔！ -->
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp重本紫腾，用爱和专业成就学子梦想！<br/><br/>
                            【广州东山口校区】 位处广州市越秀区市中心东山口，结合教学区与宿舍
                            区的独立城区综合大楼，封闭管理，交通便捷。名校围绕，学习氛围浓
                            厚，有效提高学习效率，培养良好的学习习惯。<br/><br/>
                            <!-- 【广州海珠昌岗校区】 位处广州市海珠区昌岗中心，全封闭独立园区，拥
                            有一流的硬件设施和教学、生活环境，集吃住学与一体的封闭式管理的校
                            区。 -->
                        </div>
                        <div class="founder-top">
                            <img src="../assets/brand-top.png" />
                        </div>
                    </Col>
                    <Col flex="1.8"></Col>
                </Row>
            </Content>

            <Content class="content-teachingCh" v-if="roll > 1">
                <Row>
                    <Col flex="3.2">
                    </Col>
                    <Col flex="16">
                        <img src="../assets/teachingTip.png" style="margin-top: 2.1875rem;"/>
                    </Col>
                    <Col flex="4.8">
                        
                    </Col>
                </Row> 
                <Row style="margin-top: 2.8125rem;">
                    <Col flex="3.2">
                    </Col>
                    <Col flex="18">
                        <ul>
                            <li>
                                <div class="teachingCh-top">
                                    <div class="teachingCh-idx">01</div> 
                                    <span>多年沉淀</span>
                                    <img src="../assets/left-arrow.png"/>
                                </div>
                                <div class="teachingCh-tab">
                                    <img src="../assets/img/01.png"/>
                                    <div class="teachingCh-span">重本紫腾——原明师复读学校，创办于2009年，并于2021年进行全面升级，赋予专属品牌——重本·紫腾。</div>
                                </div>
                            </li>

                            <li>
                                <div class="teachingCh-top">
                                    <div class="teachingCh-idx">02</div> 
                                    <span>分层教学</span>
                                    <img src="../assets/left-arrow.png"/>
                                </div>
                                <div class="teachingCh-tab">
                                    <img src="../assets/img/02.png"/>
                                    <div class="teachingCh-span">依据学生在入学前最后一次全国高考或广州中考的各科成绩，按科目进行不同层级班别的划分。跟本学科同一水平层次的学生一起学习，效果更加高，形成一种积极向上的学习竞争氛围。</div>
                                </div>
                            </li>

                            <li>
                                <div class="teachingCh-top">
                                    <div class="teachingCh-idx">03</div> 
                                    <span>封闭管理</span>
                                    <img src="../assets/left-arrow.png" style="object-fit: none;"/>
                                </div>
                                <div class="teachingCh-tab">
                                    <img src="../assets/img/03.png"/>
                                    <div class="teachingCh-span">市中心独栋大楼全封闭管理培训，严格的考勤机制及手机管理制度。学生在校情况全程跟踪，定期召开家长会，反馈学生在校情况，与家长保持紧密联系。</div>
                                </div>
                                <div class="teachingCh-left"><img src="../assets/left-wheel.png"/></div>
                            </li>
                            
                        </ul>
                    </Col>
                    <Col flex="2.8">
                        
                    </Col>
                </Row> 

                <Row style="margin-top: 4.8rem;">
                    <Col flex="3.2">
                            <!-- <div class="teachingCh-right"><img src="../assets/right-wheel.png"/></div> -->
                    </Col>
                    <Col flex="18">
                        <ul>
                            <li>
                                <div class="teachingCh-top">
                                    <div class="teachingCh-idx">06</div> 
                                    <span>专属成长导师</span>
                                    <img src="../assets/right-arrow.png"/>
                                </div>
                                <div class="teachingCh-tab">
                                    <img src="../assets/img/06.png"/>
                                    <div class="teachingCh-span">每位同学配备专属导师，负责指导学生在大型考试前后的备考规划、心理辅导及成绩跟踪等工作，真正实现关怀每一位学生。</div>
                                </div>
                            </li>

                            <li>
                                <div class="teachingCh-top">
                                    <div class="teachingCh-idx">05</div> 
                                    <span>班主任责任制</span>
                                    <img src="../assets/right-arrow.png"/>
                                </div>
                                <div class="teachingCh-tab">
                                    <img src="../assets/img/05.png"/>
                                    <div class="teachingCh-span">以班主任为主导，负责所负责班级全体学生的思想、学习、生活的教育与管理，培养学习榜样，促进后进生转化，建设形成良好的班风，奋发向上的集体。</div>
                                </div>
                            </li>

                            <li>
                                <div class="teachingCh-top">
                                    <div class="teachingCh-idx">04</div> 
                                    <span>师资力量</span>
                                    <img src="../assets/right-arrow.png"/>
                                </div>
                                <div class="teachingCh-tab">
                                    <img src="../assets/img/04.png"/>
                                    <div class="teachingCh-span">任职老师具备多年的毕业班教学及管理经验，熟知考试的重难点，教学手段有的放矢。</div>
                                </div>
                            </li>
                        </ul>
                    </Col>
                    <Col flex="2.8">
                        <!-- <div class="teachingCh-left"><img src="../assets/left-wheel.png"/></div> -->
                    </Col>
                </Row> 

                <Row style="margin-top: 4.8rem;">
                    <Col flex="3.2">
                            <div class="teachingCh-left" style="left: 9rem;top: -34.9rem;"><img src="../assets/right-wheel.png"/></div>
                    </Col>
                    <Col flex="18">
                        <ul>
                            <li>
                                <div class="teachingCh-top">
                                    <div class="teachingCh-idx">07</div> 
                                    <span>特优食宿</span>
                                    <img src="../assets/left-arrow.png"/>
                                </div>
                                <div class="teachingCh-tab">
                                    <img src="../assets/img/07.png"/>
                                    <div class="teachingCh-span">宿舍分四人间、六人间，男女宿舍分开，有一名宿管老师负责管理。学校伙食营养配置餐饮，保证学生精力足以应付紧张学习。</div>
                                </div>
                            </li>

                            <li>
                                <div class="teachingCh-top">
                                    <div class="teachingCh-idx">08</div> 
                                    <span>课余活动</span>
                                    <!-- <img src="../assets/right-arrow.png"/> -->
                                </div>
                                <div class="teachingCh-tab">
                                    <img src="../assets/img/08.png"/>
                                    <div class="teachingCh-span">定期举行户外活动以及传统节日庆祝活动，如师生运动会、春游踏青、元宵节汤圆DIY、中秋节灯笼大赛、新年许愿等活动，让学生在复读的紧张生活中得到适时放松，劳逸结合，提高学习效率。</div>
                                </div>
                            </li>
                        </ul>   
                    </Col>
                    <Col flex="2.8">
                        <!-- <div class="teachingCh-left"><img src="../assets/left-wheel.png"/></div> -->
                    </Col>
                </Row> 
            </Content>

            <Content class="content-teachTeam" v-if="roll > 1">
                <Row>
                    <Col flex="3.2">
                    </Col>
                    <Col flex="16">
                        <img src="../assets/teacherTeam.png" style="margin-top: 35px;"/>
                    </Col>
                    <Col flex="4.8">
                        <div class="teacher-more" data-v-47323bf2=""><!---->
                            <a href="javascript:void(0);"><span @click="toLinkTa(4)">查看更多</span></a>
                        </div>
                    </Col>
                </Row>       
                <Row style="margin-top:4.1875rem;">
                    <Col flex="3.2">
                    </Col>
                    <Col flex="18">
                        <div class="teachTeamLst" v-for="(item, i) in teacherTeams" :key="i">
                            <img
                                :src="item.avatar"
                            />
                            <div class="teachTeamTip">
                                <span>{{ item.name.substring(0,1)+'老师' }} | {{ item.subjectName }} </span>
                                <span style="width: 100%; float: left;font-size: 16px;">{{ item.university }}</span>
                            </div>
                        </div>
                    </Col>
                    <Col flex="4"></Col>
                </Row>  
            </Content>

            <Content class="content-album" v-if="roll > 1 && latestAlbumIdx !== ''">
                <Row style="margin-top: 80px;">
                    <Col flex="3.2">
                    </Col>
                    <Col flex="18">
                        <img src="../assets/album.png"/>
                    </Col>
                    <Col flex="2.8"></Col>
                </Row> 
                <Row >
                    <Col flex="3.2">
                    </Col>
                    <Col flex="17">
                        <div class="schoolAlbum">
                            <div class="schoolAlbum-tb">
                                <div class="schoolAlbum-title">{{showAlbum.title}}</div>
                                <div class="schoolAlbum-column">{{showAlbum.columnType}}</div>

                                <div class="schoolAlbum-content">{{showAlbum.content}}</div>
                                <div style="height: 30%; padding: 45px 0 0 45px;">
                                    <div class="teacher-more" data-v-47323bf2=""><!---->
                                        <a href="javascript:void(0);"><span @click="toLinkTa(5)">查看更多</span></a>
                                    </div>
                                </div>
                            </div>
                            <div class="schoolAlbum-banner" >
                                <img :src="showAlbum.banner">
                            </div>
                        </div>
                    </Col>
                    <Col flex="3.8" style="margin-bottom: 60px;">
                        <div style="height:60%;"></div>
                        <div><a href="javascript:void(0);"><img @click="showAlbumSelect(latestAlbumIdx - 1)" src="../assets/top.png"/></a></div>
                        <div><a href="javascript:void(0);"><img @click="showAlbumSelect(latestAlbumIdx + 1)" src="../assets/down.png"/></a></div>
                    </Col>
                </Row>  
            </Content>


            <Content class="content-qa" v-if="roll > 1">
                <Row style="margin-top: 80px;">
                    <Col flex="3.2">
                    </Col>
                    <Col flex="16">
                        <img src="../assets/qa.png"/>
                    </Col>
                    <Col flex="5.8">
                        <div class="notice-cul" data-v-47323bf2=""><!---->
                            <a href="/qaLst"><span>查看更多</span></a>
                        </div>
                    </Col>
                </Row>

                <Row style="margin-top: 20px;">
                    <Col flex="3.2">
                    </Col>
                    <Col flex="18" class="collapse-content">
                        <div class="ivu-collapse" data-v-47323bf2="">
                            <div class="ivu-collapse-item" style="letter-spacing: 3px; padding-left: 27px; height: 5.12rem;" data-v-47323bf2="">
                                <template v-for="(item,i) in qlList" :key="i">
                                    <div @mousemove="selectQA(item.index)" class="notice-cul" :class="qlIndex === item.index ? 'ivu-tag-checked cul-checked' : ''" data-v-47323bf2=""><!---->
                                        <a href="javascript:void(0);"><span >{{item.name}}</span><!----></a>
                                    </div>
                                </template>
                            </div>
                            <div v-for="(item, i) in qaSelect" :key="i" class="ivu-collapse-item ivu-collapse-content-boxTip" data-v-47323bf2="">
                                <div class="ivu-collapse-header" @click="openQa(i)">
                                    <img src="../assets/Q.png"/>
                                    <span>{{(i+1) +'：'+ item.askQuestions}}</span>
                                    <div style="float: right; margin-top: -40px;width: 25px;"><img style="width:20px; height:20px;" :src="item.show === 0 ? require('../assets/down-l.png') : require('../assets/up-l.png')" /></div>
                                </div>
                                <div class="ivu-collapse-content" data-old-padding-top="" data-old-padding-bottom=""
                                data-old-overflow="" :style="item.show === 0 ? 'display: none;' : 'display: block;'">
                                    <div class="ivu-collapse-content-box">
                                        <img src="../assets/A.png" style="float: left;"/> 
                                        <div v-html="item.answer" class="qa-answer"></div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </Col>
                    <Col flex="3.8"></Col>
                </Row>
            </Content>

            <!-- <Content class="content-mapGps"> -->
            <div style="background-color:#fff; width:100%; max-width: 1920px;margin: 0 auto; height:37.5rem;">
                <div id="allmap" ref="allmap" style="width: 72%;margin-left: 13%; height:100%;"></div>
            </div>    
            <!-- </Content> -->

            <Footer class="layout-footer-center footer-webChain">
                <Row style="margin-top: 50px;">
                    <Col flex="3.2">
                    </Col>
                    <Col flex="18" style="text-align: left;">
                        <img src="../assets/webChain.png"/>
                    </Col>
                    <Col flex="2.8"></Col>
                </Row>
                <Row style="margin-top: 50px;">
                    <Col flex="3.2">
                    </Col>
                    <Col flex="18">
                        <div class="webChain-lst">
                            <div v-for="(item,i) in webChain" :key="i" class="ivu-tag ivu-tag-size-large ivu-tag-default webchain" data-v-47323bf2=""><!---->
                                <a href="javascript:void(0);"><span @click="openWebChain(item)">{{item.name}}</span></a><!---->
                            </div>
                        </div>      
                    </Col>
                    <Col flex="2.8"></Col>
                </Row>
            </Footer>

            <Footer class="layout-footer-center about">
                <Row>
                    <Col flex="3.2">
                    </Col>
                    <Col flex="18">
                        <div class="main df">
                            <div class="left df">
                            <img src="../assets/img/bt-log.png"
                                class="bt-logo" alt="重本紫腾复读">
                            <div class="about-option">
                                <div class="key-word">重本紫腾
                                </div>
                                <div class="option"><a href="../../static/introduce.htm">创办历史</a></div>
                                <div class="option"
                                    @click="go(1)">品牌介绍</div>
                                <div class="option"
                                    @click="go(2)">创始人介绍</div>
                                <div class="option"
                                    @click="go(3)">办学特色</div>
                            </div>
                            <div class="about-option" style="padding-top:30px;">
                                <!-- <div class="key-word">
                                <div class="point"></div>联系我们
                            </div> -->
                                <div class="option"><a href="https://www.zhongbenziteng.com/gf-pc/index.html">高考复读</a></div>
                                <div class="option"><a href="https://www.zhongbenziteng.com/zf-pc/index.html">中考复读</a></div>
                                <div class="option"><a href="https://www.zhongbenziteng.com/yt-pc/index.html">艺体生文化课培训</a></div>
                                <div class="option"><a href="https://www.zhongbenziteng.com/zt-pc/pcgaozhi.html">高职高考</a></div>
                            </div>
                            </div>
                            <div class="qrcode">
                            
                                <div class="qr-option">
                                    <img src="../assets/zbzt-douyin.png"
                                        alt="中高复读_重本紫腾抖音号"
                                        class="qr" style="border: 4px solid #fff;">
                                    <div class="word">抖音号</div>
                                </div>
                                <div class="qr-option">
                                    <img src="../assets/zbzt-vlog.png"
                                        alt="中高复读_视频_重本紫腾视频号"
                                        class="qr">
                                    <div class="word">视频号</div>
                                </div>
                                <div class="qr-option">
                                    <img src="../assets/zbzt-wechat.jpg"
                                        alt="中高复读_了解更多_重本紫腾公众号"
                                        class="qr">
                                    <div class="word">公众号</div>
                                </div>
                            
                            </div>
                        </div>    
                    </Col>
                    <Col flex="2.8"></Col>
                </Row>
                <Row>
                    <!-- <Col flex="3.2">
                    </Col> -->
                    <Col flex="24">
                        <div class="bottom">
                            <b>© 广州市越秀区紫腾教育培训中心有限公司 版权所有。</b>
                            <a class="beian"
                                href="https://beian.miit.gov.cn"  rel="nofollow">粤ICP备2021074338号</a>
                            <!-- | 本公司荣获SGS的ISO9001:2008认证 |
                            增值电信业务经营许可证：粤B2-20170231 Copyright (C) 2002-2018 |
                            <a class="beian" href="https://gdca.miit.gov.cn/">广东省通信管理局</a> -->
                        </div>
                    </Col>
                    <!-- <Col flex="2.8"></Col> -->
                </Row>
            </Footer>

            <div class="backFast" v-if="roll >= 1">
                <div class="ivu-back-top ivu-back-top-show" data-v-47323bf2="" style="bottom: 500px; right: 30px;">
                    
                    <div class="customerSys bak customerSysWm" data-v-47323bf2="" @click="openWechat">
                        <img src="../assets/customerSys.png">
                    </div>
                    <div class="weixin_wm">
                        <img src="../assets/weixin_wm.png"/>
                    </div>
                </div>
                <div class="ivu-back-top ivu-back-top-show" data-v-47323bf2="" style="bottom: 420px; right: 30px;">
                    <div class="followUs bak" data-v-47323bf2="">
                        <img src="../assets/followUs.png">
                    </div>
                    <div class="erweima_wm">
                        <!-- <img src="../assets/erweima_wm.png"/> -->
                        <img src="../assets/zbzt-douyin.png"/>
                        抖音号
                        <img src="../assets/zbzt-vlog.png"/>
                        视频号
                        <img src="../assets/zbzt-wechat.jpg"/>
                        公众号
                    </div>
                    
                </div>
                <div class="ivu-back-top ivu-back-top-show" data-v-47323bf2="" style="bottom: 340px; right: 30px;">
                    <div class="enrollNow bak" data-v-47323bf2="" @click="openEnrollNow">
                        <img src="../assets/enrollNow.png">
                    </div>
                </div>
                <!-- <div class="ivu-back-top ivu-back-top-show" data-v-47323bf2="" style="bottom: 260px; right: 30px;"> -->
                    <BackTop :height="100" :bottom="260">
                        <div class="backTop bak" data-v-47323bf2="">
                            <img src="../assets/backTop.png">
                        </div>
                    </BackTop>
                <!-- </div> -->

               
            </div>
        </Layout>
    </div>
</template>
<script>
import api from '../api/index'
import { Image, Row } from 'view-ui-plus';
import DevicePixelRatio from '../libs/rem';
import Base64 from '../../public/static/js/base64'
import moment from 'moment'

    export default {
    components: { Image, Row },
    data() {
        return {
          headLogin: require("../assets/zbzt-log.png"),
          headBg: require("../assets/bg-01.png"),
          roll: 2,
          map: null,
          videoFull: 1,
          point: [{'y': 113.299501, 'x' : 23.129081}, {'y': 113.28441, 'x': 23.092146}],
          zoom: '',
          loading: true,
          fitVlue: 1,
          enrollNowModal: false,
          actColumnType: 1,
          activityNoticeAll: [],
          actColumnLst:[],
          actColumn: [
            {
                'type': 1,
                'name': '热门话题'
            },
            {
                'type': 2,
                'name': '政策解读'
            },
            {
                'type': 3,
                'name': '校内动态'
            },
            {
                'type': 4,
                'name': '学生成长'
            }
          ],
          menu: [
            {
                name: '首页',
                icon: 'ios-navigate',
                index: 1,
                url: "/"
            },
            {
                name: '最新动态',
                icon: 'ios-keypad',
                index: 2,
                url: "/noticeLst"
            },
            {
                name: '师资团队',
                icon: 'ios-paper',
                index: 4,
                url: "/teachTeam"
            },
            {
                name: '视频',
                icon: 'ios-paper',
                index: 7,
                url: "/videoLst"
            },
            {
                name: '相册',
                icon: 'ios-paper',
                index: 5,
                url: "/albumLst"
            },{
                name: '资料下载',
                icon: 'ios-paper',
                index: 6,
                url: "/dataDownload"
            },
            {
                name: 'Q&A',
                icon: 'ios-paper',
                index: 8,
                url: "/qaLst"
            }
          ],
          teachTeam: [
            {
                "alt": "罗宇恒",
                "teacherImg": require("../assets/teacher1.jpg")
                ,"name": "罗宇恒",
            },
            {
                "alt": "罗宇恒",
                "teacherImg": require("../assets/teacher1.jpg")
                ,"name": "罗宇恒",
            },
            {
                "alt": "罗宇恒",
                "teacherImg": require("../assets/teacher1.jpg")
                ,"name": "罗宇恒",
            },
            {
                "alt": "罗宇恒",
                "teacherImg": require("../assets/teacher1.jpg")
                ,"name": "罗宇恒",
            },
            {
                "alt": "罗宇恒",
                "teacherImg": require("../assets/teacher1.jpg")
                ,"name": "罗宇恒",
            }
          ],
          studentTypeLst: [
            {
                'value': 1,
                'label': '初三（应届/往届）'
            },
            {
                'value': 2,
                'label': '高三（应届/往届）'
            },
            {
                'value': 3,
                'label': '高职'
            },
          ],
          schoolAlbum: [
            {
                "title": "这是一个标题22",
                "introduce": "这是一个介绍这是一个介绍这是一个介绍这是一个介绍这是一个介绍这是一个介绍这是一个介绍这是一个介绍这是一个介绍这是一个介绍这是一个介绍",
                "albumImg": require("../assets/album/a1.jpg")              
            },
            {
                "title": "这是一个标题33",
                "introduce": "这是一个介绍这是一个介绍这是一个介绍这是一个介绍这是一个介绍这是一个介绍这是一个介绍这是一个介绍这是一个介绍这是一个介绍这是一个介绍",
                "albumImg": require("../assets/album/a2.jpg")              
            },
            {
                "title": "这是一个标题55",
                "introduce": "这是一个介绍这是一个介绍这是一个介绍这是一个介绍这是一个介绍这是一个介绍这是一个介绍这是一个介绍这是一个介绍这是一个介绍这是一个介绍",
                "albumImg": require("../assets/album/a3.jpg")              
            }
          ],
          mobileRegx2018: new RegExp(/^[1][3,4,5,6,7,8,9][0-9]{9}$/),
          formItem: {
            name: '',
            studentType: '',
            school: '',
            phone: ''
          },
          ruleInline: {
            name: [
                { required: true, message: '学生姓名不能为空', trigger: 'blur' }
            ],
            studentType: [
                { required: true, message: '学生类型不能为空', trigger: 'blur' }
            ],
            school: [
                { required: true, message: '最近就读学校不能为空', trigger: 'blur' }
            ],
            phone: [
                { required: true, message: '手机号码不能为空', trigger: 'blur' }
            ]
          },
          teacherTeams: [],
          latestAlbums: [],
          latestAlbumIdx: '',
          showAlbum: {},
          qlIndex: 0,
          qaTopOpenIndex: 2,
          qlList: [
            {
                'name': '中复',
                'index': 1
            },{
                'name': '高复',
                'index': 2
            },{
                'name': '其他',
                'index': 3
            },
          ],
          qaSelect: [],
          webChain: [
            {
                'name': '广州招考',
                'url': 'http://gzzk.gz.gov.cn/'
            },{
                'name': '广州市教育局',
                'url': 'http://jyj.gz.gov.cn/'
            },{
                'name': '教育部',
                'url': 'http://www.moe.gov.cn/'
            },{
               'name': '省考试院',
               'url': 'https://eea.gd.gov.cn/'
            }
          ]
        }
    },
    created () {
        new DevicePixelRatio().init();
    },
    methods: {
        toNofollow (item) {
            if (item && item.url) {
                this.addClick(item.id.toString())
                if (item.url.indexOf('www.zhongbenziteng.com') != -1) {
                    if (item.isOuterChain === 0) {
                        this.$router.push('/article/'+item.id)
                    }else{
                        window.location.href = item.url
                    }
                }else{
                    window.location.href = item.url
                }
            }
            // this.addClick(item.id.toString())
            // window.location.href = process.env.VUE_APP_HOST_URL + 'article/' + item.id
        },
        addClick (click) {
            var that = this;
            api.addActClick({
                'click' : new Base64().encode(click) ,
                'secret': new Base64().encode('asDD54@@#hdjk' + moment(new Date()).format("yyyy-MM-DD"))
            }).then(function (response) {})
                .catch(function (error) {
                // console.log(error.resultMessage);
            });
        },
        getQaInit() {
            let that = this
            api.getQaTopLst({
                'type': this.qlIndex
            }).then(function(rep){
                // console.log(rep)
                if(rep.resultCode === 0) {
                    let qaDataLst = rep.data

                    if (qaDataLst !== null && qaDataLst.length > 0) {
                        for(let i = 0; i < qaDataLst.length; i++) {
                            if (that.qlIndex === that.qaTopOpenIndex && i === 0) {
                                qaDataLst[i].show = 1
                                that.qaTopOpenIndex = ''
                            }else{
                                qaDataLst[i].show = 0
                            }
                        }
                    }
                    that.qaSelect = qaDataLst
                }
            })
        },
        //  视频窗口点击进入全屏事件
        videoFullscreen(videoElement) {

            if (!this.iffullscreen) {
                console.log('窗口')
                // this.launchFullscreen(document.getElementById(videoElement))
                this.launchFullscreen(videoElement)
                this.iffullscreen = false
                this.videoFull = 1
            } else {
                console.log('全屏')
                this.iffullscreen = true
                this.exitFullscreen()
                this.videoFull = 0
            }
        },
        //    进入全屏
        launchFullscreen(videoElement) {
            const refsArr = this.$refs.videoElement
            for (let i = 0; i < refsArr.length; i++) {
                if (refsArr[i].id === videoElement) {
                console.log(222)
                let currElement = document.getElementById(videoElement)
                if (currElement.requestFullscreen) {
                    currElement.requestFullscreen()
                } else if (currElement.mozRequestFullScreen) {
                    currElement.mozRequestFullScreen()
                } else if (currElement.msRequestFullscreen) {
                    currElement.msRequestFullscreen()
                } else if (currElement.oRequestFullscreen) {
                    currElement.oRequestFullscreen()
                } else if (currElement.webkitRequestFullscreen) {
                    currElement.webkitRequestFullScreen()
                }
                }
            }
        },
        //    退出全屏
        exitFullscreen() {
            if (document.exitFullscreen) {
                document.exitFullscreen()
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen()
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen()
            } else if (document.oRequestFullscreen) {
                document.oCancelFullScreen()
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen()
            }
        },
        openWebChain(item) {
            window.open(item.url)
        },
        openQa(idx) {
            let show = this.qaSelect[idx].show
            if(show == 1) {
                this.qaSelect[idx].show = 0
            }else{
                this.qaSelect[idx].show = 1
            }
        },
        selectQA(index) {
            if (index !== this.qlIndex) {
                this.qlIndex = index
                this.getQaInit()
            }
        },
        toLinkTa(idx) {
            let url=''
            for (let i = 0; i < this.menu.length; i++) {
                let item = this.menu[i];
                if (item.index === idx) {
                    url = item.url
                    break;
                }
            }
            this.$router.push({path: url});
        },
        actColumnTo(index) {
            if (index !== this.actColumnType) {
                this.getActivityNotices(index)
                this.actColumnType = index
            }
        },
        getActivityNotices(type) {
            //1-热门话题  2-政策解读  3-校内动态  4-学生成长
            let that = this
            api.getNewestNotice(
                {
                    "columnType": type
                }
            ).then(function(rep){
                console.log(rep)

                if(rep !== null && rep !== undefined && rep.resultCode === 0) {
                    that.activityNoticeAll = rep.data
                    
                    if (that.activityNoticeAll != null) {
                        for (let i = 0; i < that.activityNoticeAll.length; i++) {
                            let notice = that.activityNoticeAll[i]
                            if (notice.columnType === type){
                                if (notice.title.length > 28) {
                                    notice.title = notice.title.substring(0, 26) + '..'
                                }

                                if (notice.description.length > 95) {
                                    notice.description = notice.description.substring(0, 95) + '..'
                                }
                            }

                        }
                    }
                }
            })
        },
        getTopFaculrtTeam() {
            let that = this
            api.getTopFaculrtTeam().then(function(rep){
                console.log(rep)
                if(rep !== null && rep !== undefined && rep.resultCode === 0) {
                    that.teacherTeams = rep.data
                }
            })
        },
        showAlbumSelect(idx) {
            if (this.latestAlbums !== null && this.latestAlbums.length > 0) {
                let size = this.latestAlbums.length - 1

                if (idx < 0 ) {
                    idx = size
                } else if (idx > size) {
                    idx = 0
                }
                this.latestAlbumIdx = idx

                let album = this.latestAlbums[idx]

                this.showAlbum.title = album.title

                if (album.introduction.length > 115) {
                    album.introduction = album.introduction.substring(0, 115) + '..'
                }

                // this.showAlbum.columnType = album.groupTypeName
                this.showAlbum.content = album.introduction
                this.showAlbum.banner = album.imageUrl
            }
        },
        getLatestAlbum() {
            let that = this
            api.geAlbum({
                'id': 5
            }).then(function(rep){
                console.log(rep)
                if(rep !== null && rep !== undefined && rep.resultCode === 0) {
                    if(rep.data != null && rep.data.contentImages != null) {
                        that.latestAlbums = rep.data.contentImages

                        if (that.latestAlbums !== null && that.latestAlbums.length > 0) {
                            that.latestAlbumIdx = 0;

                            let album = that.latestAlbums[0]

                            // that.showAlbum.columnType = album.groupTypeName

                            if (album.title.length > 20) {
                                album.title = album.title.substring(0, 20) + '..'
                            }

                                                        
                            if (album.introduction.length > 115) {
                                album.introduction = album.introduction.substring(0, 115) + '..'
                            }
                            
                            that.showAlbum.title = album.title
                            that.showAlbum.content = album.introduction
                            that.showAlbum.banner = album.imageUrl
                        }
                    }
                    
                }
            })
        },
        mapInit() {
            var markerArr = [
						{ title: "<br/><span style='font-size:20px; font-weight: 600;'>广州东山口校区  [走读&住宿]</span><br/>广东省广州市越秀区东华北路168号重本·紫腾大楼 (金城宾馆旁)<br/>电话：191 2861 8254 严老师 <br/>电话：020 8944-8086</div>", point: "113.299501, 23.129081", address: "广东省广州市越秀区东华北路168号金城宾馆2号楼(金城宾馆旁)", tel: "19128618254 严老师" }
						// { title: "<br/><span style='font-size:20px; font-weight: 600;'>海珠昌岗校区  [走读&住宿]</span><br/>广州市海珠区礼岗路16号荣大工业园2号楼<br/>电话：191 2861 8254 严老师 <br/>电话：020 8944-8086</div>", point: "113.28441,23.092146", address: "广州市海珠区礼岗路16号荣大工业园2号楼", tel: "19128618254 严老师" }
					];
				
                    var map = new BMap.Map("allmap"); // 创建Map实例
                    var point = new BMap.Point(113.3311,23.112106); //地图中心点，广州市
                    map.centerAndZoom(point, 13); // 初始化地图,设置中心点坐标和地图级别。
                    // map.enableScrollWheelZoom(true); //启用滚轮放大缩小
                    //地图、卫星、混合模式切换
                    map.addControl(new BMap.MapTypeControl({mapTypes: [BMAP_NORMAL_MAP, BMAP_SATELLITE_MAP, BMAP_HYBRID_MAP]}));
                    //向地图中添加缩放控件
                    var ctrlNav = new window.BMap.NavigationControl({
                        anchor: BMAP_ANCHOR_TOP_LEFT,
                        type: BMAP_NAVIGATION_CONTROL_LARGE
                    });
                    map.addControl(ctrlNav);

                    //向地图中添加缩略图控件
                    var ctrlOve = new window.BMap.OverviewMapControl({
                        anchor: BMAP_ANCHOR_BOTTOM_RIGHT,
                        isOpen: 1
                    });
                    map.addControl(ctrlOve);

                    //向地图中添加比例尺控件
                    var ctrlSca = new window.BMap.ScaleControl({
                        anchor: BMAP_ANCHOR_BOTTOM_LEFT
                    });
                    map.addControl(ctrlSca);

                    var point = new Array(); //存放标注点经纬信息的数组
                    var marker = new Array(); //存放标注点对象的数组
                    var info = new Array(); //存放提示信息窗口对象的数组
                    var searchInfoWindow =new Array();//存放检索信息窗口对象的数组
                    for (var i = 0; i < markerArr.length; i++) {
                        var p0 = markerArr[i].point.split(",")[0]; //
                        var p1 = markerArr[i].point.split(",")[1]; //按照原数组的point格式将地图点坐标的经纬度分别提出来
                        point[i] = new window.BMap.Point(p0, p1); //循环生成新的地图点
                        marker[i] = new window.BMap.Marker(point[i]); //按照地图点坐标生成标记
                        map.addOverlay(marker[i]);

　　　　　　　　　　　　　　//显示marker的title，marker多的话可以注释掉
                        // var label = new window.BMap.Label(markerArr[i].title, { offset: new window.BMap.Size(20, -10) });
                        var x=-500;
                        var y=-150;
                        if(i > 0){
                            x=30;
                            y=-10;
                        }


                        var labelContent =
                            '<div style="line-height: 30px;font-size:16px; height:220px;padding:20px;font-weight: 400;background-color: rgb(255 255 255 / 69%);border-radius: 10px;box-shadow: rgb(136, 136, 136) 0px 0px 10px;">'
                            +'<img src="https://msboss-prd.oss-cn-shenzhen.aliyuncs.com/webSite/PC/addre.png">'
                            + markerArr[i].title
                            + '</div>';

                        var label = new BMap.Label(labelContent, {offset:new BMap.Size(x, y), position: point[i]});
                            label.setStyle({
                                color : "black",
                                fontSize : "13px",
                                backgroundColor :"rgb(255 255 255 / 60%)",
                                // padding:"20px",
                                border : "0px solid #fff",
                                borderRadius : "10px",
                                fontWeight :"bold",
                                height:"220px",
                                width:"490px"
                            });

                        marker[i].setLabel(label);

                        // 创建信息窗口对象
                        info[i] = "<p style=’font-size:12px;lineheight:1.8em;’>" + "</br>地址：" + markerArr[i].address + "</br> 电话：" + markerArr[i].tel + "</br></p>";
                        //创建百度样式检索信息窗口对象                       
                        searchInfoWindow[i] = new BMapLib.SearchInfoWindow(map, info[i], {
                                title  : markerArr[i].title,      //标题
                                width  : 350,             //宽度
                                height : 80,              //高度
                                panel  : "panel",         //检索结果面板
                                enableAutoPan : true,     //自动平移
                                searchTypes   :[
                                    BMAPLIB_TAB_SEARCH,   //周边检索
                                    BMAPLIB_TAB_TO_HERE,  //到这里去
                                    BMAPLIB_TAB_FROM_HERE //从这里出发
                                ]
                            });
                        //添加点击事件
                        marker[i].addEventListener("click", 
                            (function(k){
                                // js 闭包
                                return function(){
                                    //将被点击marker置为中心
                                    //map.centerAndZoom(point[k], 18);
                                    //在marker上打开检索信息窗口
                                    searchInfoWindow[k].open(marker[k]);
                                }
                            })(i)                            
                        ); 
                    }          
            // let cgPoint = new BMap.Point(113.28441, 23.092146);
        },
        scrolling () {
            // 滚动条距文档顶部的距离
            let scrollTop =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop;
            // 滚动条滚动的距离
            let scrollStep = scrollTop - this.oldScrollTop;
            // console.log("header 滚动距离 ", scrollTop);
            // 更新——滚动前，滚动条距文档顶部的距离
            this.oldScrollTop = scrollTop;

            //变量windowHeight是可视区的高度
            let windowHeight =
                document.documentElement.clientHeight || document.body.clientHeight;
            //变量scrollHeight是滚动条的总高度
            let scrollHeight =
                document.documentElement.scrollHeight || document.body.scrollHeight;

            //滚动条到底部的条件
            if (scrollTop + windowHeight == scrollHeight) {
                //你想做的事情
                // console.log("header  你已经到底部了");
            }
            if (scrollStep < 0) {
                // console.log("header 滚动条向上滚动了！");
            } else {
                // console.log("header  滚动条向下滚动了！");
                if(scrollTop > (scrollHeight * 0.1) && this.roll === 0) {
                    this.roll = 2
                }

                // console.log("header  滚动条向下滚动了！");
                // if(scrollTop > 500) {
                //     this.roll = 2
                // }
            }
            // 判断是否到了最顶部
            if (scrollTop <= 100) {
                // console.log("header 到了最顶部")

                if(this.roll > 1) {
                    this.roll = 0
                }
            } 
    
        },
        openEnrollNow () {
            window.open('https://m.liangpinedu.cn/special/eventsgd/index.html?n=20210516jdyx#gwzxbmldy')
            return
        },
        openWechat () {
            window.open('https://work.weixin.qq.com/kfid/kfc47fa1af615683c07')
            return
        },
        handleSubmit(name) {
            if(this.formItem.name === '') {
                this.$Message.warning('学生姓名不能为空')
                return
            }

            if(this.formItem.studentType === '') {
                this.$Message.warning('学生类型不能为空')
                return
            }

            if(this.formItem.school === '') {
                this.$Message.warning('最近就读学校不能为空')
                return
            }

            if(this.formItem.phone === '') {
                this.$Message.warning('手机号码不能为空')
                return
            }

            if (!this.mobileRegx2018.exec(this.formItem.phone)) {
                this.$Message.warning('请输入正确手机号码')
                return
            }

        },
        toLink(item) {
            this.$router.push({path: item.url});
        }
    },
    mounted () {
        window.addEventListener("scroll", this.scrolling);
        this.scrolling();
        this.mapInit();
        this.getActivityNotices(1);
        this.getTopFaculrtTeam();
        this.getLatestAlbum();

        this.selectQA(2)
        // this.mapConfig();
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.scrolling);
    }
}
</script>

<style scoped>
.layout{
    background: #f8d4d4;
    position: relative;
    border-radius: 0.25rem;
    overflow: hidden;
    font-weight: 400;
}

#header a{
    color: #fff;
}

.layout .ivu-layout-header {
    position: fixed;
    z-index: 999;
    /* background: rgba(255,255,255,0.3); */
    height: 8rem;
    width: 100%;
    background: none; 
    padding: 0;
    top: 0;
}

.layout .ivu-image {
    line-height: 9rem;
    width: 100%;
}

.layout .ivu-text-center {
    height:8.5rem;
}

.ivu-menu-dark {
    background: none;
}

.ivu-menu-dark.ivu-menu-horizontal .ivu-menu-item, .ivu-menu-dark.ivu-menu-horizontal .ivu-menu-submenu {
    font-size: 1rem;
}

.ivu-menu-dark.ivu-menu-horizontal .ivu-menu-item:hover{
    color: #641f77;   
}

.layout-logo{
    width: 6.25rem;
    height: 1.876rem;
    background: #ffffff;
    border-radius: 0.1875rem;
    float: left;
    position: relative;
    top: 0.9375rem;
    left: 1.25rem;
}
.layout-nav{
    /* width: 420px; */
    /* width:60%; */
    float:left;
    margin: 0 auto;
    margin-right: 1.25rem;
}
.layout-footer-center{
    text-align: center;
    padding-top: 3.75rem;
}

.layout-topMiddleBottom .ivu-menu-light{
    background:none;
}
.tel {
    font-size: 1.5rem;
    font-size: xx-large;
    margin-top: 0.9rem;
}
.tel .tel-tip {
    width: 3.5rem;
    font-size: 1.2rem;
    line-height: 1.5rem;
    float: left;
}

.tel .tel-phone {
    float: left;
    margin-top: -0.5rem;
}

.header-bg {
    position: relative;
    height: 100%;
    width: 100%;
}

.ivu-col{
    z-index: 999;
}

.content-video {
    position: relative;
    /* height: 67.5rem; */
    z-index: 1;
    background-color: #fff;
    padding-top: 2rem;
    max-width: 1920px;
    margin: 0 auto;
    width: 100%;
}

.content-video .video-card {
    /* width: 20rem; */
    border-radius: 2rem;
    /* height: 38rem; */
    float: right;
    padding: 0;
    height: 52.5rem;
    width: 28.875rem;
}

.content-video .content-card {
    width: 17.5rem;
    margin-bottom: 2.5rem;
    height: 25rem;
    float: left;
    margin-right: 2.375rem;
    border-radius: 2.25rem;

}

.backFast .bak{
    width: 4.5rem;
    height: 4.5rem;
    background: #fff;
    border-radius: 0.125rem;
    box-shadow: rgb(136, 136, 136) 0.375rem 0.375rem 0.375rem;
}

.backFast .bak img {
    width: 4.5rem;
    height: 4.5rem;
}

.customerSys:hover {
    content: url(../assets/customerSys-hover.png);
}

.customerSysWm:hover+.weixin_wm {
    display: block;
}

.followUs:hover {
    content: url(../assets/followUs-hover.png);
}

.followUs:hover+.erweima_wm {
    display: block;
}

.enrollNow:hover {
    content: url(../assets/enrollNow-hover.png);
}

.backTop:hover {
    content: url(../assets/backTop-hover.png);
}

.weixin_wm {
    position: fixed;
    right: 6.875rem;
    display: none;
    margin-top: -4.5rem;
    box-shadow: rgb(136, 136, 136) 0.375rem 0.375rem 0.75rem;
    width: 12.5rem;
    height: 12.5rem;
}
.weixin_wm img{
    width: 12.5rem;
    height: 12.5rem;
}

.erweima_wm {
    position: fixed;
    right: 6.875rem;
    display: none;
    -webkit-box-shadow: rgb(136, 136, 136) 0.375rem 0.375rem 0.75rem;
    box-shadow: rgb(136, 136, 136) 0.375rem 0.375rem 0.75rem;
    width: 12.5rem;
    padding: 0.875rem;
    text-align: center;
    bottom: 20%;
    background-color: #fff;
}
.erweima_wm img{
    width: 10rem;
    height: 10rem;
}

.vertical-center-modal{
    display: flex;
    align-items: center;
    justify-content: center;
}
.vertical-center-modal .ivu-modal{
    top: 0;
}

.content-notice {
    position: relative;
    height: 46.125rem;
    z-index: 1;
    padding-top: 2rem;
    max-width: 1920px;
    margin: 0 auto;
    width: 100%;
}

.notice-lst {
    margin-top: 2.54rem;
    height: 21.875rem;
    padding: 1.875rem;
}

.notice-banner {
    padding-left: 3.125rem;
    background-image: url('~@/assets/notice-bakimg.png'); 
    background-repeat: no-repeat;
    padding: 0.625rem 0 0 0.625rem;
    margin-left: 1.875rem;
    margin-right: 1.875rem;
}

.notice-date {
    border-right: 0.0625rem solid #9E9E9E;
    padding: 0.3125rem;
    line-height: 1.5625rem;
    text-align: center;
    margin-right: 0.625rem;
}

.ivu-list-item-meta-title {
    font-size: 1.5rem;
    margin-bottom: 1.125rem;
    color: #333333;
}

.ivu-list-item-meta-description {
    font-size: 1.125rem;
    color: #333333;
}

.notice-more {
    background-color: #E7E7E7;
    border: 0.0625rem solid #E7E7E7;
    width: 10rem;
    height: 3.625rem;
    text-align: center;
    font-size: 1.5rem;
    border-radius: 0.3125rem;
    padding-top: 0.75rem;
}

.teacher-more {
    background-color: #F9F2FF;
    border: 0.0625rem solid #F9F2FF;
    width: 10rem;
    height: 3.625rem;
    text-align: center;
    font-size: 1.5rem;
    border-radius: 0.3125rem;
    padding-top: 0.75rem;
}

.teacher-more span {
    color: #641f77;
}

.teacher-more:hover {
    border: 0.0625rem solid #641f77;
}

.notice-more span {
    color: #333333;
}

.notice-more:hover {
    border: 0.0625rem solid #333333;
}

.notice-cul {
    background-color: #E7E7E7;
    border: 0.0625rem solid #E7E7E7;
    width: 9rem;
    height: 3.625rem;
    text-align: center;
    font-size: 1.5rem;
    border-radius: 0.3125rem;
    padding-top: 0.75rem;
    float: left;
    margin-right: 2.25rem;
}


/* .notice-cul span:hover{
    border-bottom: 1px solid #000;
} */

.notice-cul span{
    color: #515a6e;
}

.cul-checked {
    background-color: #fff;
    box-shadow: rgb(136, 136, 136) 0.0625rem 0.0625rem 0.3125rem;
}

.cul-checked span {
    color: #641f77;
}

.content-aboutUs {
    position: relative;
    height: 60rem;
    background-color: #fcfcfc;
    z-index: 1;
    padding-top: 2rem;
    max-width: 1920px;
    margin: 0 auto;
    width: 100%;
}

.about-brand {
    font-size: 1.125rem;
    font-weight: 400;
    color: #000;
    line-height: 1.5rem;
    text-indent: 2em;
    padding: 1.875rem;
    letter-spacing:0.125rem;
}

.about-founder {
    font-size: 1.125rem;
    font-weight: 400;
    color: #000;
    line-height: 1.5rem;
    text-indent: 2em;
    padding: 1.875rem;
    letter-spacing: 0.125rem;
}

.content-teachingCh {
    position: relative;
    z-index: 1;
    padding-top: 2rem;
    background-color: #f5f5f5;
    height: 121.25rem;
    max-width: 1920px;
    margin: 0 auto;
    width: 100%;
}

.content-teachingCh ul li{
    list-style: none;
    height: 31.25rem;
    width: 25rem;
    float: left;
    margin-right: 4rem;
}

.teachingCh-idx {
    width: 2.25rem;
    height: 2.25rem;
    background-color: rgb(255, 101, 93);
    border-radius: 0.5625rem;
    color: #fff;
    text-align: center;
    line-height: 2.1875rem;
    font-size: 1.375rem;
    float: left;
}

.teachingCh-top {
    height: 2.25rem;
}

.teachingCh-top span {
    color: #000000;
    font-size: 1.5rem;
    margin-left: 0.375rem;
    float: left;
}

.teachingCh-top img {
    margin: 0.8rem auto;
    margin-left: 0.5rem;
    width: 50%;
}

.teachingCh-left {
    position: relative;
    left: 10rem;
    top: -31.9rem;
}

.teachingCh-right {
    position: absolute;
    right: 1rem;
    top: 0.875rem;
}

.teachingCh-tab {
    width: 23.75rem;
    height: 28.75rem;
    padding: 1.875rem;
    background-color: #fff;
    margin-top: 1.9375rem;
    border-radius: 1rem;
}

.teachingCh-tab img {
    width: 20rem;
    height: 13.25rem;
    margin: 0 auto;
}

.teachingCh-span {
    margin: 1.25rem auto;
    font-size: 1.25rem;
    width: 20rem;
}

.content-teachTeam {
    position: relative;
    z-index: 1;
    padding-top: 3rem;
    background-color: #fff;
    max-width: 1920px;
    margin: 0 auto;
    width: 100%;
}

.teachTeamLst {
    height: 25rem;
    width: 13.625rem;
    float: left;
    margin-left: 2.9rem;
    margin-bottom: 2rem;
}

.teachTeamLst img {
    width: 100%; 
    border-radius: 20px; 
    box-shadow: rgb(215 211 211) 6px 6px 12px
}

.teachTeamTip {
    margin-top: -58px;
    opacity: 0.8;
    background-color: #00000087;
    position: relative;
    z-index: 8;
    width: 100%;
    height: 55px;
    border-radius: 0 0 20px 20px;
    backdrop-filter: blur(5px);
    text-align: center;
    color: #fff;
    font-size: 20px;
}

.teachTeamLst p {
    margin-top: -25px;
    z-index: 22;
    position: relative;
    text-align: center;
    font-size: 20px;
    color: #fff;
    line-height: 0px;
    letter-spacing: 2px;
}

.content-album {
    position: relative;
    z-index: 1;
    background-color: #fff;
    height: 50rem;
    background-image: url('../assets/album-backImg.png');
    background-repeat: round;
    background-size: cover;
    max-width: 1920px;
    margin: 0 auto;
    width: 100%;
}

.schoolAlbum {
    height: 500px;
    margin-top: 60px;
}

.content-qa {
    position: relative;
    z-index: 1;
    background-color: #fff;
    padding-bottom: 70px;
    max-width: 1920px;
    margin: 0 auto;
    width: 100%;
}

.ivu-collapse {
    background-color: #ffffff;
    border: none;
}

.ivu-collapse-item {
    margin-bottom: 15px;
    /* border: 1px solid #dcdcdc; */
    background-color: #f3f3f3;
    border-radius: 20px;
    /* line-height: 45px; */
    padding: 15px;
}

.ivu-collapse-item .ivu-tag-size-large{
    margin-right: 30px;
    width: 100px;
}

.ivu-collapse-content {
    background-color: #f3f3f3;
}

.ivu-collapse-content-boxTip img{
    width: 30px;
    height: 30px;
}

.ivu-collapse-content-boxTip span{
    margin-left: 38px;
    margin-top: -45px;
    display: block;
    font-size: 16px;
    letter-spacing: 1px;
}

.footer-webChain {
    background-color: #fff;
    z-index: 1;
    padding-bottom: 4rem;
    height: 19.375rem;
    width:100%; 
    max-width: 1920px;
    margin: 0 auto;
}


.about {
    width: 100%;
    height: 30rem;
    background: #F3F3F3;
    padding: 0;
 }
  
.about .left {
  width: 50%;
}

.left .bt-logo {
    width: 15.4375rem;
    height: 13.25rem;
}
  

.about-option .key-word {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 1.75rem;
  font-weight: bold;
  color: #333333;
}

.key-word .point {
	width: 6px;
	height: 6px;
	margin-right: 3px;
	border-radius: 50%;
	background-color: #000;
	overflow: hidden;
    text-align: left;
  }
	
.about-option .option {
  margin-bottom: 10px;
  font-size: 1.125rem;
  font-weight: 400;
  color: #333333;
  text-align: left;
}

.about-option .option span:nth-child(2n) {
	margin-left: 20px;
}

.about-option .option a {
	/* font-size: 12px; */
	font-weight: 400;
	color: #333333;
	text-decoration: none;
}


.about-option .strong {
  font-weight: bold;
}

.about .qrcode {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 45%;
  height: 100%;  
}

.about .qrcode .qr-option {
	width: 10.5rem;
}

.qr-option .qr {
  width: 10.5rem;
  height: 10.5rem;
}

.qr-option .word {
  margin-top: 6px;
  text-align: center;
  font-size: 1.125rem;
  font-weight: 400;
  color: #333333;
}
  
.bottom {
	width: 100%;
	height: 30px;
	font-size: 14px;
	line-height: 30px;
	color: #999;
	text-align: center;
	background-color: #4a4a4a;
}

.df {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main {
  width: 84rem;
  height: 23.625rem;
  margin: 2.375rem auto;
}

.webChain-lst {
    background-color: #f9f5fb;
    border-radius: 15px;
    height: 80px;
    margin-top: -35px;
}

.webchain {
    font-size: 25px;
    font-weight: 400;
    width: 22%;
    line-height: 65px;
    height: 100%;
}

.webchain span{
    color: #641f77;
}

.webchain span:hover{
    border-bottom: 1px solid #641f77;
}

video:focus {
    outline: -webkit-focus-ring-color auto 0px;
}

.video-play {
    width: 100%; 
    height: 100%; 
    border-radius: 2rem; 
}

.founder-content {
    position: relative;
    z-index: 10;
    width: 50%;
    float: left;
    height: 100%;
    background-color: #fff;
    box-shadow: rgb(136, 136, 136) 0 0 0.625rem;
    padding: 1.875rem;
    line-height: 1.75rem;
    font-size: 1.2rem;
    color: #000000;
}

.founder-bg {
    position: absolute;
    z-index: 5;
    background-color: #6d2c7f;
    width: 86%;
    height: 45.625rem;
    float: right;
    right: 45px;
}

.founder-top {
    width: 45%;
    float: left;
    margin-top: -3rem;
    position: relative;
    height: 45.625rem;
    z-index: 10;
    background-color: #fff;
}

.founder-top img {
    width: 100%;
    height: 100%;
    float: left;
    object-fit: cover;
}

.schoolAlbum-tb {
    width:45%; 
    height: 100%; 
    float: left;
}

.schoolAlbum-title {
    height: 20%;
    padding: 45px;
    font-size: 30px;
    letter-spacing: 3px;
    color: #000;
    font-weight: bold;
}
.schoolAlbum-column {
    height: 10%;
    padding-left: 45px;
    font-size: 25px;
    letter-spacing: 3px;
    line-height: 45px;
}

.schoolAlbum-content {
    height: 30%; 
    padding-left: 45px; 
    font-size: 20px; 
    letter-spacing: 1px;
}

.qa-answer {
    width: 90%;
    height: 100%;
    margin-left: 40px;
    line-height: 30px;
    font-size: 16px;
    letter-spacing: 1px;
}

.homeClumn:hover {
    color: #641f77;
    background-image: url('../assets/selected.png');
    background-repeat: repeat-y 100px;
    background-repeat: no-repeat;
    background-position-x: 50%;
    background-position-y: 20px;
    background-size: 60px 60px;
}

.homeClumn {
    font-size: 1.125rem;
    font-weight: 400;
    display: block;
    text-align: center;
    width: 5rem;
}

.content-tab {
    height: 100%;
    width: 100%;
}

.content-tab img{
    width: 100%;
}

.ivu-card-body {
    height: 100%;
    width: 100%;
    padding: 0;
}

.content-card span {
    margin-top: -55px;
    display: block;
    font-size: 1.75rem;
    color: #fff;
    text-align: center;
}

.schoolAlbum-banner {
    width: 50%;
    height: 95%;
    float: left;
    padding: 30px;
    margin-top: -39px;
    text-align: center;
}

.schoolAlbum-banner img {
    max-width: 100%;
    height: 100%;
    box-shadow: rgb(136, 136, 136) 7px 5px 6px;
}

.BMapLabel {
    border-radius: 10px;
    background-color: rgb(236, 241, 236,0.5);
}

.hand-a {
    color: #000; 
    background-color: #FFF; 
    box-shadow: 6px 6px 6px #888888;
}

.hand-b {
    color: #fff; 
    background-color: transparent; 
    box-shadow: none;
}

.ivu-affix,.ivu-back-top {
    position: fixed;
    z-index: 999;
}
</style>